.slideRightContainer {
  -webkit-animation:
    slideRight 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  animation:
    slideRight 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

.slideLeftContainer {
  -webkit-animation:
    slideLeft 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  animation:
    slideLeft 0.25s ease-in-out,
    opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

@keyframes slideRight {
  from {
    transform: translateX(-100px);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100px);
  }
}
